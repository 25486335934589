<template>
    <BlackFridayHeader v-if="pageHasEvent && hasEvent" />
    <NuxtLoadingIndicator :color="'#474747'" />
    <GlobalHeader></GlobalHeader>
    <NuxtPage></NuxtPage>
    <GlobalFooter></GlobalFooter>
    <VEditor />
</template>
<script lang="ts" setup>
import BlackFridayHeader from '../app/views/home/components/BlackFridayHeader.vue'
import { useHasEvent } from '../app/stores/useEventHeader'
const storeHasEvent = useHasEvent()
const hasEvent = computed(() => storeHasEvent.hasEvent)
const pageHasEvent = ref(false)
const route = useRoute()
watch(
    () => route.href,
    () => {
        if (route.href !== '/') {
            return (pageHasEvent.value = false)
        }
        return (pageHasEvent.value = true)
    },
    { immediate: true }
)
</script>
